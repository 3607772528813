/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/** Import Bootstrap */
// require("jquery/dist/jquery.min.js")
// require("popper.js/dist/popper.min")
// require("bootstrap/js/dist/util")
// require("bootstrap/js/dist/carousel")
// require("bootstrap/js/dist/dropdown")

const wrapWithI18nProvider = require("./src/components/wrapWithI18nProvider")
  .wrapWithI18nProvider
exports.wrapPageElement = wrapWithI18nProvider
