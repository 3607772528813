// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-archive-js": () => import("./../../../src/templates/Archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-order-product-js": () => import("./../../../src/templates/OrderProduct.js" /* webpackChunkName: "component---src-templates-order-product-js" */),
  "component---src-templates-photos-js": () => import("./../../../src/templates/Photos.js" /* webpackChunkName: "component---src-templates-photos-js" */),
  "component---src-templates-privacy-js": () => import("./../../../src/templates/Privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/Shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/Video.js" /* webpackChunkName: "component---src-templates-video-js" */)
}

